module.exports = new Promise((resolve, reject) => {
  const retries = window.location.hostname === 'localhost' ? 0 : 5;
  const delay = 300;
  const timeout = 5000;

  const remoteUrlWithVersion = 'https://1on1.xto10x.com/remoteEntry.js';
  let script = document.createElement('script');
  script.src = remoteUrlWithVersion;

  let retryCount = 0;
  let timeoutId;

  const sendGAEvent = (message) => {
    if(window.ga && window.ga.hasOwnProperty("loaded")) {
      ga('send', 'event', 'Remote Entry',  'oneOnOne', message)
    }
  }

  const onError = (event) => {
    if (retryCount < retries) {
      retryCount++;
      setTimeout(() => {
        const retryLabel = 'Retried_' + retryCount;
        sendGAEvent(retryLabel)
        document.head.removeChild(script);
        script = document.createElement('script');
        script.src = remoteUrlWithVersion + '?v=1724738317900';
        script.addEventListener('error', onError);
        script.addEventListener('load', onSuccess);
        document.head.appendChild(script);
      }, delay);
    } else {
      sendGAEvent('Failed')
      clearTimeout(timeoutId);
      reject(new Error('Timeout while loading script: oneOnOne'));
    }
  };

  const onSuccess = (event) => {
    const proxy = {
      get: (request) => window.oneOnOne.get(request),
      init: (arg) => {
        try {
          return window.oneOnOne.init(arg)
        } catch(e) {}
      }
    }
    sendGAEvent('Loaded')
    script.removeEventListener('error', onError);
    clearTimeout(timeoutId);
    resolve(proxy);
  };

  script.addEventListener('error', onError);
  script.addEventListener('load', onSuccess);

  // inject this script with the src set to the versioned remoteEntry.js
  document.head.appendChild(script);
  timeoutId = setTimeout(onError, timeout);
});